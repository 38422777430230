import React from 'react';
import classNames from 'classnames';
import { Box } from '@stitch-fix/mode-react';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { type CloudinaryImageFields } from '../CloudinaryImageWrapper';
import { GetStartedButton } from '../GetStartedButton';
import styles from './style.module.scss';
import { useStickyCtaContext } from '../StickyCta/useStickyCtaContext';
import { isEmpty } from '../JsonRteRenderer/JsonRteRenderer';
import type { ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import { CloudinaryPicture } from '../CSCloudinaryAsset/Picture';

export interface HeroProps {
  title: ContentStackJSONRichText;
  subtitle: ContentStackJSONRichText;
  fontColor: 'white' | 'gray-16' | 'blue-45';
  contentBackground: 'none' | 'white';
  cta: {
    title: string;
    href: string;
  };
  media: {
    mobile: CloudinaryImageFields;
    desktop: CloudinaryImageFields;
  };
}

export const Hero = ({
  title,
  subtitle,
  fontColor,
  contentBackground,
  cta,
  media,
}: HeroProps) => {
  const { setStickyContainerTarget } = useStickyCtaContext();

  return (
    <div className={styles.container} ref={setStickyContainerTarget}>
      <div className={classNames(styles.media)}>
        {media.mobile.cloudinaryAsset && media.desktop.cloudinaryAsset && (
          <CloudinaryPicture
            alt={media.desktop.alt}
            sources={[
              {
                minWidth: 0,
                cloudinaryAsset: media.mobile.cloudinaryAsset,
              },
              {
                minWidth: 560,
                cloudinaryAsset: media.desktop.cloudinaryAsset,
              },
            ]}
            imageProps={{
              fetchPriority: 'high',
            }}
          />
        )}
      </div>
      <Box
        className={classNames(styles.content, {
          [styles.whiteContentBackground]: contentBackground === 'white',
        })}
      >
        <Box
          className={classNames({
            [styles.textShadow]: fontColor === 'white',
          })}
          mb={1.5}
        >
          <JsonRteRenderer
            data={title}
            options={{
              textProps: { h1: { color: fontColor } },
            }}
          />
          {!isEmpty(subtitle?.children) && (
            <JsonRteRenderer
              data={subtitle}
              options={{
                textProps: { p: { color: fontColor, spacingBottom: false } },
              }}
            />
          )}
        </Box>
        <GetStartedButton
          copy={cta.title}
          href={cta.href}
          eventLocation="hero"
        />
      </Box>
    </div>
  );
};
