import { Box, Button, useTheme } from '@stitch-fix/mode-react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useStickyCtaContext } from './useStickyCtaContext';

export type StickyCtaProps = {
  cta: {
    title: string;
    href: string;
  };
};

// The StickyCta component is hidden when the Hero component is in view.
// To use StickyCta, wrap the parent element  of the Hero and the StickyCta in <StickyCtaContextProvider>.

export const StickyCta = ({ cta }: StickyCtaProps) => {
  const { isStickyCtaHidden } = useStickyCtaContext();
  const isRebranded = useTheme() === 'brand-2024-full';

  return (
    <Box
      className={classNames(styles.stickyCtaContainer, {
        [styles.hidden]: isStickyCtaHidden,
        [styles.shown]: !isStickyCtaHidden,
      })}
    >
      <Button
        as="a"
        variant={isRebranded ? 'filled-accent-large' : 'filled-statement'}
        width={{ sm: 'fill' }}
        href={cta.href}
      >
        {cta.title}
      </Button>
    </Box>
  );
};
