import React from 'react';
import { Carousel, Text, useTheme } from '@stitch-fix/mode-react';
import { G } from '@mobily/ts-belt';
import { trackCarouselPanelScroll } from '../../../../utils/events';
import { GetStartedButton } from '../../../../components/GetStartedButton';
import { DynamicAdsImage, type DynamicAdsImageProps } from './DynamicAdsImage';
import styles from './style.module.scss';
import type { ContentStackJSONRichText } from '../../../../../scripts/schemaGenerator/fields/rte';
import { isNonEmptyString } from '../../../../utils/helpers';
import {
  isEmpty,
  JsonRteRenderer,
} from '../../../../components/JsonRteRenderer/JsonRteRenderer';
import { useStickyCtaContext } from '../../../../components/StickyCta/useStickyCtaContext';

const imageAspectRatio = {
  sm: {
    w: 327,
    h: 327,
  },
  xl: {
    w: 417,
    h: 417,
  },
};

export interface DynamicAdsHeroProps {
  ctaHref?: string;
  ctaText?: string;
  description?: string | ContentStackJSONRichText;
  eyebrow?: string;
  title: string | ContentStackJSONRichText;
  eventInfo: {
    location: string;
    name: string;
  };
  images: DynamicAdsImageProps['image'][];
}

const HeroText = ({
  title,
  eyebrow,
  description,
}: Pick<DynamicAdsHeroProps, 'title' | 'eyebrow' | 'description'>) => {
  const isRebranded = useTheme() === 'brand-2024-full';

  return (
    <>
      {isNonEmptyString(eyebrow) && (
        <Text
          setting="eyebrow-medium"
          as="h1"
          className={styles.eyebrow}
          {...(isRebranded && { color: 'blue-45' })}
        >
          {eyebrow}
        </Text>
      )}
      {G.isString(title) ? (
        <Text
          setting="title-xlarge"
          as={isNonEmptyString(eyebrow) ? 'h2' : 'h1'}
          className={styles.title}
          {...(isRebranded && { color: 'blue-45' })}
        >
          {title}
        </Text>
      ) : (
        <JsonRteRenderer
          data={title}
          options={{
            color: isRebranded ? 'blue-45' : undefined,
            textProps: { h1: { align: 'left' } },
          }}
        />
      )}
      {G.isString(description) ? (
        <Text
          className={styles.description}
          as="p"
          {...(isRebranded && { color: 'blue-45' })}
        >
          {description}
        </Text>
      ) : (
        !isEmpty(description?.children) && (
          <JsonRteRenderer
            data={description}
            options={{
              color: isRebranded ? 'blue-45' : undefined,
              textProps: { p: { align: 'left' } },
            }}
          />
        )
      )}
    </>
  );
};

export const DynamicAdsHero = ({
  ctaHref = '/signup',
  ctaText = 'Take your style quiz',
  eyebrow,
  title,
  description,
  eventInfo,
  images,
}: DynamicAdsHeroProps) => {
  const { setStickyContainerTarget } = useStickyCtaContext();
  const isRebranded = useTheme() === 'brand-2024-full';

  const handleDynamicAdsImageView = () => {
    trackCarouselPanelScroll({
      eventContext: {
        carousel_panel: eventInfo,
      },
    });
  };

  return (
    <div
      className={styles.container}
      data-testid="dynamic-ads-hero"
      ref={setStickyContainerTarget}
    >
      <div className={styles.content}>
        <HeroText title={title} eyebrow={eyebrow} description={description} />
        <div className={styles.cta}>
          <GetStartedButton
            copy={ctaText}
            href={ctaHref}
            eventLocation="hero"
            {...(isRebranded && { variant: 'filled-accent-large' })}
          />
        </div>
      </div>
      <div className={styles.media}>
        <Carousel
          ariaLabel="Dynamic Ads Carousel"
          slideWidth={{
            sm: `${imageAspectRatio.sm.w}px`,
            xl: `${imageAspectRatio.xl.w}px`,
          }}
          gutterOverflow={{ sm: 1.5, md: 3, lg: 0 }}
        >
          {images.map(image => (
            <DynamicAdsImage
              key={image.src}
              image={image}
              aspectRatio={imageAspectRatio}
              onView={handleDynamicAdsImageView}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};
