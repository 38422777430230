import { S } from '@mobily/ts-belt';
import { Text } from '@stitch-fix/mode-react';
import { AnimatePresence, m } from 'framer-motion';
import { GetStartedButton } from '../GetStartedButton';
import { GalleryHeroProps } from './GalleryHero';
import styles from './styles.module.scss';

export type ContentItemProps = {
  slideIndex: number;
} & Pick<GalleryHeroProps, 'items'>;

// If the current slide does not have a major title, we recursively check for the
// slide before it, until we find one
const getPopulatedTitle = (
  items: GalleryHeroProps['items'],
  slideIndex: number,
): GalleryHeroProps['items'][number] => {
  const slide = items[slideIndex];

  if (slide.majorTitle === '' && slideIndex > 0) {
    return getPopulatedTitle(items, slideIndex - 1);
  }

  return slide;
};

export const ContentItem = ({ items, slideIndex }: ContentItemProps) => {
  const { majorTitle } = getPopulatedTitle(items, slideIndex);
  const slide = items[slideIndex];
  const shouldDisplayCTAButton =
    S.isNotEmpty(slide.cta.text) && S.isNotEmpty(slide.cta.url);

  const label =
    items.length > 0 ? `${items[0].minorTitle} ${items[0].majorTitle}` : '';

  return (
    <>
      <h1 className={styles.title} aria-label={label}>
        <Text setting="display-medium" as="span" color="blue-45">
          {slide.minorTitle}
        </Text>{' '}
        <div className={styles.rotator}>
          <AnimatePresence initial={false}>
            <m.div
              key={majorTitle}
              initial={{ y: 50 }}
              animate={{ y: 0 }}
              exit={{ y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <Text setting="display-xlarge" as="span" color="blue-45">
                <strong>{majorTitle}</strong>
              </Text>
            </m.div>
          </AnimatePresence>
        </div>
      </h1>

      {slide.CtaButton ||
        (shouldDisplayCTAButton && (
          <GetStartedButton
            copy={slide.cta.text}
            href={slide.cta.url}
            eventLocation="hero"
            variant="filled-accent-large"
          />
        ))}
    </>
  );
};
