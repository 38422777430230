import Head from 'next/head';
import { useClientI18n } from '@stitch-fix/sf-next';
import { useRouter } from 'next/router';
import { loadTranslations } from '../../i18n';
import translations from './index-translations.json';

type HrefLang = {
  lang: string;
  href: string;
};

export interface SEODataProps {
  title?: string;
  description?: string;
  openGraphTitle?: string;
  openGraphDescription?: string;
  openGraphImageUrl?: string;
  robots?: string;
  canonicalUrl?: string;
  googleValidation?: string;
  bingValidation?: string;
  hrefLang?: HrefLang & {
    internationalHrefLang?: HrefLang[];
  };
}

const { useTranslation } = loadTranslations(translations, 'seo');

export const DEFAULT_SHARE_IMAGE_URL =
  'https://res.cloudinary.com/stitch-fix/image/upload/v1663083307/landing-pages/components/SEOData/referral_link_sharing.jpg';

export const SEOData = ({
  title = 'Your Online Personal Stylist | Stitch Fix',
  description,
  openGraphTitle = 'Stitch Fix, Your Personal Stylist',
  openGraphDescription,
  openGraphImageUrl = DEFAULT_SHARE_IMAGE_URL,
  robots,
  canonicalUrl,
  googleValidation,
  bingValidation,
  hrefLang,
}: SEODataProps) => {
  const { t } = useTranslation();
  const { locale } = useClientI18n();

  const { asPath } = useRouter();

  const normalizedPath = asPath.split('?')[0];

  const defaultCanonicalUrl = `https://www.stitchfix.com${normalizedPath}`;

  return (
    <Head>
      {/* Title of the page */}
      <title>{title}</title>
      {/* Short description of the document */}
      {/* This content may be used as a part of search engine results. */}
      <meta name="description" content={description ?? t('description')} />

      {/* Control the behavior of search engine crawling and indexing */}
      {robots && <meta name="robots" content={robots} />}

      {/* Helps prevent duplicate content issues */}
      <link rel="canonical" href={canonicalUrl || defaultCanonicalUrl} />

      {/* Social metadata */}
      <meta name="fb:app_id" content={process.env.NEXT_PUBLIC_FACEBOOK_ID} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@stitchfix" />

      {/*
        additional meta tags
        TODO: Remove when we can upgrade sf-next
        https://github.com/stitchfix/growth-next-ui/pull/218
      */}
      <meta name="apple-itunes-app" content="app-id=1022579925" />
      <meta
        name="facebook-domain-verification"
        content="x5k145dyv664rwswb6vlof99nwjhkd"
      />

      {/* Verify website ownerships */}
      {googleValidation && (
        <meta name="google-site-verification" content={googleValidation} />
      )}
      {bingValidation && <meta name="msvalidate.01" content={bingValidation} />}

      {/* Open Graph metadata  - https://ogp.me/ */}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={openGraphTitle} />
      <meta
        property="og:description"
        content={openGraphDescription ?? t('description')}
      />
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:image" content={openGraphImageUrl} />
      <meta property="og:image:width" content="526" />
      <meta property="og:image:height" content="274" />

      {/* References to localized versions of the page */}
      {hrefLang && (
        <link rel="alternate" hrefLang={hrefLang.lang} href={hrefLang.href} />
      )}
      {hrefLang?.internationalHrefLang?.map(item => (
        <link
          key={item.lang}
          rel="alternate"
          hrefLang={item.lang}
          href={item.href}
        />
      ))}
    </Head>
  );
};
