import {
  Box,
  Button,
  Stack,
  type StackProps,
  useTheme,
} from '@stitch-fix/mode-react';
import classNames from 'classnames';
import { z } from 'zod';
import { linkField } from '../../../scripts/schemaGenerator/fields/linkField';
import styles from './styles.module.scss';

export type ModuleFooterProps = {
  link: z.infer<typeof linkField>;
  hideOnMobile: boolean;
} & Partial<Pick<StackProps, 'hAlign'>>;

export const ModuleFooter = ({
  link: { href, title },
  hAlign = 'start',
  hideOnMobile,
}: ModuleFooterProps) => {
  const isRebranded = useTheme() === 'brand-2024-full';

  return (
    <Box
      className={classNames({
        [styles.hideOnMobile]: hideOnMobile,
      })}
    >
      <Stack hAlign={hAlign} spacing={1} mt={isRebranded ? 2.5 : 3}>
        <Button
          as="a"
          href={href}
          variant={isRebranded ? 'filled-accent-large' : 'filled-statement'}
        >
          {title}
        </Button>
      </Stack>
    </Box>
  );
};
