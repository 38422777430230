import classNames from 'classnames';
import { Box } from '@stitch-fix/mode-react';
import { useEffect, useState, useRef } from 'react';
import { CloudinaryImageFields } from '../CloudinaryImageWrapper';
import { MediaItem } from './MediaItem';
import styles from './styles.module.scss';
import { ContentItem } from './ContentItem';
import { useStickyCtaContext } from '../StickyCta/useStickyCtaContext';

type GalleryHeroItem = {
  minorTitle: string;
  majorTitle: string;
  cta: {
    text: string;
    url: string;
  };
  media?: {
    mobile: CloudinaryImageFields;
    desktop: CloudinaryImageFields;
  };
  CtaButton?: React.ReactNode;
};

export type GalleryHeroProps = {
  items: GalleryHeroItem[];
  layout?: 'full' | 'split';
};

const SLIDE_DURATION = 2500;

export const GalleryHero = ({ layout = 'full', items }: GalleryHeroProps) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { setStickyContainerTarget } = useStickyCtaContext();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const requestAnimationId = useRef<number>(0);

  useEffect(() => {
    const handleSlideAutoAdvance = () => {
      let start = performance.now();

      const tick = (timestamp: number) => {
        if (timestamp - start >= SLIDE_DURATION) {
          setShouldAnimate(true);
          setActiveSlideIndex(
            prevSlideIndex => (prevSlideIndex + 1) % items.length,
          );
          start = timestamp;
        }
        requestAnimationId.current = requestAnimationFrame(tick);
      };

      requestAnimationId.current = requestAnimationFrame(tick);
    };

    handleSlideAutoAdvance();

    return () => cancelAnimationFrame(requestAnimationId.current);
  }, [items.length]);

  return (
    <Box
      ref={setStickyContainerTarget}
      className={classNames(styles.container, {
        [styles.splitView]: layout === 'split',
      })}
    >
      <Box className={styles.media}>
        {items.map((item, index) => (
          <MediaItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            slideIndex={index}
            activeSlideIndex={activeSlideIndex}
            items={items}
            initialAnimate={shouldAnimate}
          />
        ))}
      </Box>
      <Box className={styles.content}>
        <ContentItem slideIndex={activeSlideIndex} items={items} />
      </Box>
    </Box>
  );
};
