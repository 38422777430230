import {
  Box,
  Grid,
  ResponsiveSection,
  Stack,
  useTheme,
} from '@stitch-fix/mode-react';
import { JsonRteRenderer } from '../JsonRteRenderer';
import {
  CloudinaryImageWrapper,
  type CloudinaryImageFields,
} from '../CloudinaryImageWrapper';
import { isEmpty } from '../JsonRteRenderer/JsonRteRenderer';
import { ModuleWrapper, type ModuleWrapperProps } from '../ModuleWrapper';
import type { ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import { MODE_MIN_WIDTH } from '../../utils/values/mediaQueries';
import { ModularComponentProps } from '../ModularPageWrapper/ModularPageWrapper';

type GridItem = {
  content: {
    label?: ContentStackJSONRichText;
    text: ContentStackJSONRichText;
    placement: 'above' | 'below';
  };
  image: CloudinaryImageFields;
};

export type DisplayGridProps = {
  backgroundColor: 'white' | 'gray-99' | 'mint-60' | 'blue-45';
  items: GridItem[];
} & Omit<ModuleWrapperProps, 'children'>;

export const DisplayGrid = ({
  alignment,
  backgroundColor = 'white',
  moduleTitle,
  moduleFooter,
  items,
  verticalSpacing,
}: DisplayGridProps & ModularComponentProps) => {
  const isRebranded = useTheme() === 'brand-2024-full';
  const textColor = backgroundColor === 'blue-45' ? 'mint-60' : 'blue-45';

  return (
    <Box
      data-testid="display-grid-container"
      bgColor={backgroundColor}
      mt={verticalSpacing}
    >
      <ResponsiveSection
        width="xxlarge"
        px={isRebranded ? { sm: 1, lg: 3 } : 1}
      >
        <ModuleWrapper
          alignment={alignment}
          moduleTitle={moduleTitle}
          moduleFooter={moduleFooter}
          includePadding={backgroundColor !== 'white'}
          textColor={isRebranded ? textColor : undefined}
        >
          <Grid
            columns={isRebranded ? { sm: 1, md: 3 } : { sm: 1, md: 2, lg: 3 }}
            spacing={isRebranded ? { sm: [2.5, 1], lg: [2.5, 1.5] } : [1.5, 1]}
            data-testid="grid"
          >
            {items.map((item, index) => {
              const jsonRteRenderer = !isEmpty(item.content.text?.children) ? (
                <Box
                  // List is static and won't change once rendered
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  px={isRebranded ? 0 : 0.5}
                >
                  <JsonRteRenderer
                    data={item.content.text}
                    options={{
                      color: isRebranded ? textColor : 'gray-16',
                      textProps: {
                        p: {
                          spacingBottom: false,
                          setting: isRebranded ? 'body-large' : 'body-medium',
                        },
                      },
                    }}
                  />
                </Box>
              ) : null;

              const itemLabel = !isEmpty(item.content.label?.children) ? (
                <Box
                  // List is static and won't change once rendered
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  px={0.5}
                >
                  <JsonRteRenderer
                    data={item.content.label}
                    options={{
                      color: textColor,
                      textSizeMap: isRebranded ? 'display' : 'title',
                      textProps: {
                        h3: {
                          spacingBottom: false,
                        },
                      },
                    }}
                  />
                </Box>
              ) : null;

              const image = item.image.src ? (
                <CloudinaryImageWrapper
                  // List is static and won't change once rendered
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  {...item.image}
                  sources={{
                    sm: {
                      w: 550,
                    },
                  }}
                  cloudinaryAsset={item.image.cloudinaryAsset}
                  sizes={[
                    { minWidth: 0, fluidWidth: 75 },
                    { minWidth: MODE_MIN_WIDTH.md, staticWidth: 384 },
                    { minWidth: MODE_MIN_WIDTH.xl, staticWidth: 496 },
                  ]}
                  imageProps={{ loading: 'lazy' }}
                />
              ) : null;

              return (
                // List is static and won't change once rendered
                // eslint-disable-next-line react/no-array-index-key
                <Stack spacing={isRebranded ? 0.5 : 1} key={index}>
                  {item.content.placement === 'above'
                    ? [itemLabel, jsonRteRenderer, image]
                    : [image, itemLabel, jsonRteRenderer]}
                </Stack>
              );
            })}
          </Grid>
        </ModuleWrapper>
      </ResponsiveSection>
    </Box>
  );
};
